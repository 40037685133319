import React, { useState } from "react";
import './input.style.scss';

function InputCpt(props) {

    const [error, setError] = useState("");

    const validateEmail = (value) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (value !== "" && !regex.test(value)) {
            setError("Veuillez saisir une adresse email valide.");
            props.setError(true);
            return;
        }
        
        setError("");
        props.setError(false);
    };

    const handleInputChange = (event) => {
        props.updateValue && props.updateValue(event.target.value);

        if(props.type === 'email')
            validateEmail(event.target.value);
    };

    if (props.type === "textarea")
        return (
            <div className="input-cpt" style={props.style}>
                <textarea className="input" onChange={handleInputChange} placeholder={props.placeholder}></textarea>
                {error && error.length > 0 && <div className="error">{error}</div>}
            </div>
        );
    else
        return (
            <div className="input-cpt" style={props.style}>
                <input className="input" onChange={handleInputChange} placeholder={props.placeholder} type={props.type} />
                {error && error.length > 0 && <div className="error">{error}</div>}
            </div>
        );
}

export default InputCpt