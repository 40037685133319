import React from "react";
import './button.style.scss';

function ButtonCpt(props) {
    return (
        <div className={`button-cpt select-disable ${props.disabled ? 'disabled' : 'pointer'}`} style={props.style} onClick={!props.disabled ? props.click : undefined}>
            <span>{props.text}</span>
        </div>
    );
}

export default ButtonCpt