import React from 'react';
import { Trans } from 'react-i18next';
import './aboutMe.style.scss';
import { Skill } from 'views/components';

// Import picture
import myProfilePicture from 'assets/images/me.jpg';
import { useTranslation } from 'react-i18next';

function AboutMePage(props: any) {
    const [t] = useTranslation();

    // Calcul my age
    const getAge = (date) => { 
        var diff = Date.now() - date.getTime();
        var age = new Date(diff); 
        return Math.abs(age.getUTCFullYear() - 1970);
    }

    let myAge = getAge(new Date(1997, 7, 9))

    return (
        <div className="about-me-page content">
            <div className="sub-content">
                <div className='informations'>
                    <div className='text'>
                        <p>
                            <Trans i18nKey="about-me.p1" values={{age: myAge}}>
                                {[
                                    "Bonsoir, j'ai ",
                                    <span key='myAge' className='color-primary'>{{myAge}} ans</span>,
                                    " et je suis ",
                                    <span key='dev' className='color-primary'>développeur web</span>,
                                    " depuis quelques années ainsi que ",
                                    <span key='mobile' className='color-primary'>lead mobile</span>,
                                    " depuis 1 an."
                                ]}
                                </Trans>
                        </p>

                        <p>
                            <Trans i18nKey="about-me.p2">
                                {[
                                    "Mon objectif est de créer une entreprise de développement avec un ami. Ma passion principale est tout ce qui touche de près ou de loin à l'",
                                    <span key={'space'} className='color-primary'>espace</span>,
                                    "."
                                ]}
                            </Trans>
                        </p>
                        <p>{t('about-me.p3')}</p>
                    </div>
                    <div className='skills'>
                        <Skill title="Front End Developer" icon="html-five" />
                        <Skill title="Back End Developer" icon="embed2" />
                        <Skill title="Player Amateur" icon="game" />
                    </div>
                </div>
                <div className='picture'>
                    <img alt='profile' src={myProfilePicture} />
                </div>
            </div>
        </div>
    );
}

export default AboutMePage;
