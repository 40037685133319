import React, { useRef } from 'react';
import ReactPageScroller from 'react-page-scroller';

// Pages
import Home from './views/features/0-home/homePage.tsx';
import AboutMe from './views/features/1-about-me/aboutMePage.tsx';
import Project from './views/features/2-project/projectPage.tsx';
import Career from './views/features/3-career/careerPage.tsx';
import Contact from './views/features/4-contact/contactPage.tsx';

import { Title, Menu, Footer } from './views/components';

// Style
import './App.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function App() {
    const [t] = useTranslation();
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const maxWidthSmartphone = 767;
    const isPhone = maxWidthSmartphone >= windowSize.current[0];

    const [currentPage, setCurrentPage] = useState(-1);
    const [currentPageInstant, setCurrentPageInstant] = useState(-1);
    const [initNavigation, setInitNavigation] = useState(false);

    const titlePages = [
        { title: t("title.about-me"), subtitle: "Thibaut HUGUET" },
        { title: t("title.project"), subtitle: t("sub-title.project") },
        { title: t("title.career"), subtitle: t("sub-title.career") },
        { title: t("title.contact"), subtitle: t("sub-title.contact") }
    ];

    const setPaging = async (page: number) => {
        if(page === -1)
            scrollUp();
        
        setCurrentPageInstant(page);
        setCurrentPage(page);
    }

    const scrollDown = async () => {
        switchPages();
        setInitNavigation(true);

        await setPaging(0);
    }

    
    const scrollUp = async () => {
        switchPages();
        setInitNavigation(false);
    }

    const switchPages = () => {
        let pageHome = document.querySelector(".home-page");
        let pageOther = document.querySelector(".container-grid");
        pageHome.classList.toggle("active");
        pageOther.classList.toggle("active");
    }

    return (
        <div className="screen">
            {/* Home page - PAGE 1 */}
            <Home scrollDown={scrollDown} />

            {/* Other page - PAGE 2 to 4 */}
            <div className="container-grid">
                {/* Title */}
                {!isPhone && currentPage !== -1 && <Title title={titlePages[currentPageInstant].title} subtitle={titlePages[currentPageInstant].subtitle} currentPage={currentPageInstant} />}

                <div className='fake-space'></div>

                {/* Container */}
                <div className='container'>
                    {/* Big screen display */}
                    {!isPhone && currentPage !== -1 &&
                        <ReactPageScroller
                            onBeforePageScroll={setCurrentPageInstant}
                            pageOnChange={setCurrentPage}
                            customPageNumber={currentPage}
                            blockScrollDown={currentPageInstant !== currentPage}
                            blockScrollUp={currentPageInstant !== currentPage}
                            containerHeight={"calc(100vh - 17.4rem - 6rem)"}
                        >
                            <AboutMe />
                            <Project />
                            <Career />
                            <Contact />
                        </ReactPageScroller>
                    }

                    {/* Phone display */}
                    {isPhone &&
                        titlePages.map((tp, index) => {
                            let renderPage;

                            switch (index) {
                                case 0:
                                    renderPage = <AboutMe />;
                                    break;
                                case 1:
                                    renderPage = <Project />;
                                    break;
                                case 2:
                                    renderPage = <Career />;
                                    break;
                                case 3:
                                    renderPage = <Contact />;
                                    break;
                            }

                            return <>
                                <Title key={index} title={tp.title} subtitle={tp.subtitle} />
                                {renderPage}
                            </>;
                        })
                    }
                </div>

                {/* Menu */}
                {!isPhone && <Menu setPaging={setPaging} currentPage={currentPageInstant} init={initNavigation} />}

                {/* Footer */}
                <Footer init={initNavigation} />
            </div>
        </div>
    );
}

export default App;
