import React from "react";
import './skill.style.scss';

import Icon from '../icon/IconCpt.tsx';

function SkillCpt(props) {
    return (
        <div className="skill">
            <div className="icon">
                <Icon icon={props.icon} size={45} color="#1D43A6" />
            </div>
            <span className="title color-primary">{props.title}</span>
        </div>
    );
}

export default SkillCpt