import React from 'react';
import './project.style.scss';
import { Icon } from 'views/components';

// Import picture
import gmeePicture from 'assets/images/gmee.png';

function ProjectPage(props: any) {
    return (
        <div className="project-page content">
            <div className='sub-content'>
                <div className='picture'>
                    <img alt='profile' src={gmeePicture} />
                </div>
                <div className='details'>
                    <div className='text'>
                        <p><span className='color-primary'>Gmee</span> est un réseau social qui <span className='color-primary'>respecte les données personnelles</span>.</p>
                        <p>Un chat animé, des posts customisées et autres secrets font que tu peux passer un <span className='color-primary'>bon moment</span>.</p>
                        <a className='color-primary' href='https://gmee.net' target='_blank' rel="noreferrer">https://gmee.net</a>
                    </div>
                    <div className='icons'>
                        <Icon icon='react' size={45} color="rgba(255, 255, 255, .75)" />
                        <Icon icon='database' size={45} color="rgba(255, 255, 255, .75)" />
                        <Icon icon='nodejs' size={45} color="rgba(255, 255, 255, .75)" />
                        <Icon icon='figma' size={45} color="rgba(255, 255, 255, .75)" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjectPage;
