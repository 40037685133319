import anime from "animejs";
import React, { useEffect, useRef, useState } from "react";
import './title.style.scss'

function TitleCpt(props) {

    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const maxWidthSmartphone = 767;
    const isPhone = maxWidthSmartphone >= windowSize.current[0];

    // Use previous state or props
    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            setTimeout(() => {
                ref.current = value;
            }, 1300);
        });
        return ref.current;
    }

    const { currentPage, subtitle } = props
    const prevProps: any = usePrevious({ currentPage, subtitle });

    const [subtitleEffect, setSubtitleEffect] = useState<string>(props.subtitle);
    const [loadingEffect, setLoadingEffect] = useState<boolean>(false);

    useEffect(() => {
        if(isPhone)
            return;

        const scrollDown = () => {
            setLoadingEffect(true);

            // Old go to top
            risingEffectGoToTop();

            let timeout = 1200 + (30 * prevProps.subtitle.length);

            // New come from bottom
            setTimeout(() => {
                setSubtitleEffect(props.subtitle);
                risingEffectFromBottom();

                setLoadingEffect(false);
            }, timeout)
        }

        const scrollUp = () => {
            setLoadingEffect(true);

            // Old go to bottom
            risingEffectGoToBottom();

            let timeout = 1200 + (30 * prevProps.subtitle.length);

            // New come from top
            setTimeout(() => {
                setSubtitleEffect(props.subtitle);
                risingEffectFromTop();

                setLoadingEffect(false);
            }, timeout)
        }

        if (prevProps !== undefined && !loadingEffect) {
            // Scroll to the top
            if (prevProps.currentPage > props.currentPage)
                scrollUp();
            // Scroll to the bottom
            else 
                scrollDown();
        }

    }, [props.currentPage, isPhone, prevProps, props.subtitle]);

    const risingEffectGoToTop = () => {
        anime.timeline({ loop: false })
            .add({
                targets: '.s-effect .letter',
                translateY: [0, -100],
                opacity: [1, 0],
                easing: "easeInExpo",
                duration: 1200,
                delay: (el, i) => 100 + 30 * i
            });
    }

    const risingEffectGoToBottom = () => {
        anime.timeline({ loop: false })
            .add({
                targets: '.s-effect .letter',
                translateY: [0, 100],
                opacity: [1, 0],
                easing: "easeInExpo",
                duration: 1200,
                delay: (el, i) => 100 + 30 * i
            });
    }

    const risingEffectFromBottom = () => {
        anime.timeline({ loop: false })
            .add({
                targets: '.s-effect .letter',
                translateY: [100, 0],
                translateZ: 0,
                opacity: [0, 1],
                easing: "easeOutExpo",
                duration: 1400,
                delay: (el, i) => 300 + 30 * i
            });
    }

    const risingEffectFromTop = () => {
        anime.timeline({ loop: false })
            .add({
                targets: '.s-effect .letter',
                translateY: [-100, 0],
                translateZ: 0,
                opacity: [0, 1],
                easing: "easeOutExpo",
                duration: 1400,
                delay: (el, i) => 300 + 30 * i
            });
    }

    return (
        <div className="big-title">
            <h4>{props.title}</h4>
            {subtitleEffect &&
                <h1 className="subtitle s-effect">
                    {isPhone && <div>{subtitleEffect}</div>}
                    {!isPhone &&
                        subtitleEffect.split('').map((letter, index) => {
                            return <span key={subtitleEffect + letter + index} className='letter'>{letter}</span>;
                        })
                    }
                </h1>}
        </div>
    );
}

export default TitleCpt