import React, { useState } from 'react';
import {useTranslation} from "react-i18next";

import './home.style.scss';
import { Icon } from 'views/components';

// Import picture
import mePicture from 'assets/images/me_home.png';

function HomePage(props: any) {
    const [t] = useTranslation();

    const [joke, setJoke] = useState('home.joke');

    return (
        <div className="home-page active" onClick={props.scrollDown}>
            {/* My name */}
            <div className='title'>
                <span className='is'>{t('home.is')}</span>
                <span className='name'>THIBAUT</span>
                <div className='subtitle' onMouseEnter={() => setJoke('home.joke2')} onMouseLeave={() => setJoke('home.joke')}>
                    <span className='job bold'>{t('home.my-job')}</span>
                    <span className='joke bold'>({t(joke)})</span>
                </div>
            </div>

            {/* Bottom content */}
            <div className='content'>
                <div className='social-network'>
                    <span>@tiborelsan</span>
                    <div className='icons'>
                        <Icon icon="gmee" size={40} color="white" />
                        <Icon icon="instagram" size={35} color="white" />
                        <Icon icon="linkedin" size={35} color="white" />
                    </div>
                </div>
                <div className='discover-me'>
                    <div className="arrow pointer"></div>
                    <div className='circle pointer'></div>
                </div>
                <div className='dev'>
                    <span>DEV<span className='dot'>.</span></span>
                </div>
            </div>

            <img alt='profile' src={mePicture} />
        </div>
    );
}

export default HomePage;
