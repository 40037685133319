import React from 'react';
import './career.style.scss';
import { StepFrame, Icon } from 'views/components';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

function CareerPage() {
    const [t] = useTranslation();

    const stepFrames = [
        { title: "BAC", subtitle: "STI2D", icons: ["html-five", "css3", "cpp"], year: "2015" },
        { title: "BTS", subtitle: "SIO", icons: ["js", "csharp", "php", "sql-search"], year: "2017" },
        { title: "L. PRO", subtitle: "RTAI", icons: ["sass", "vuejs", "java"], year: "2018" },
        { title: "TRAVAIL", subtitle: "UBIGREEN", icons: ["typescript", "angularjs", "react", "dot-net"], year: "2018-" + t('career.today') },
        { title: "FUTUR", icons: ["help_outline"], year: t('career.today') + "-???" }
    ];

    return (
        <div className="career-page content">
            {
                _.map(stepFrames, (step, index) => {
                    return (
                        <React.Fragment key={index}>
                            <StepFrame
                                title={step.title}
                                subtitle={step.subtitle}
                                icons={step.icons}
                                year={step.year}
                                iconcolor="rgba(255, 255, 255, .75)"
                                iconsize={40}
                                style={{backgroundColor: index === stepFrames.length-1 ? '#1D43A6' : '#1A1A1A' }}
                            />
                            { index !== stepFrames.length - 1 &&
                                <div className='icon-step'>
                                    <Icon icon="chevrons-right" size={55} color="white" />
                                </div>
                            }
                            
                        </React.Fragment>
                    )
                })
            }
        </div>
    );
}

export default CareerPage;
