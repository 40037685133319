import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App.tsx';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider, initReactI18next} from "react-i18next";
import i18next from "i18next";

// Language translations
import fr_FR from "assets/translations/fr-FR.json";
import en_US from "assets/translations/en-US.json";

// Get current language
const saved = localStorage.getItem("lang");
const initialValue = saved;
const currentLang = initialValue || "fr";

i18next
    .use(initReactI18next)
    .init({
        interpolation: { escapeValue: false },  // React already does escaping
        lng: currentLang,                              // language to use
        fallbackLng: "fr",
        resources: {
            fr: {
                translation: fr_FR
            },
            en: {
                translation: en_US
            }
        },
    });

ReactDOM.render(
  <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <App />
        </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
