import React from "react";
import './stepFrame.style.scss'
import _ from 'lodash';

import Icon from '../icon/IconCpt.tsx';

function StepFrameCpt(props: any) {
    return (
        <div className="step-frame" style={props.style}>
            <div className="informations">
                <span className="title">{props.title}</span>
                { props.subtitle && <span className="sub-title">{props.subtitle}</span> }
            </div>
            <div className="icons">
                { 
                    _.map(props.icons, (icon, index) => {
                        return <Icon key={index} icon={icon} size={props.iconsize} color={props.iconcolor} />
                    })
                }
            </div>
            <span className="year">{props.year}</span>
        </div>
    );
}

export default StepFrameCpt