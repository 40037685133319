import React, { useEffect, useRef, useState } from "react";
import './footer.style.scss';
import {useTranslation} from "react-i18next";

function FooterCpt(props: any) {
    const currentYear = new Date().getFullYear();
    const [, i18n] = useTranslation();

    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const maxWidthSmartphone = 767;
    const isPhone = maxWidthSmartphone >= windowSize.current[0];

    const [lang, setLanguage] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("lang");
        const initialValue = saved;
        return initialValue || "fr";
    });

    useEffect(() => {
        localStorage.setItem("lang", lang);
        i18n.changeLanguage(lang);
    }, [i18n, lang]);

    return (
        <div className={`footer ${props.init ? 'fade-in' : 'fade-out'}`}>
            <div className="copyright">
                <span className="color-white-50">
                    <span className={`pointer ${lang === 'fr' ? 'bold color-primary' : ''}`} onClick={() => setLanguage('fr')}>FR</span>
                    <span> - </span>
                    <span className={`pointer ${lang === 'en' ? 'bold color-primary' : ''}`} onClick={() => setLanguage('en')}>EN</span>
                    <span> | </span>
                    <span className="color-primary">{currentYear} </span>
                    {isPhone && <br/>}
                    <span>Copyright by </span>
                    <span className="color-primary">THIBAUT HUGUET</span>
                </span>
            </div>
            <div className='dev'>
                <span>DEV<span className='color-primary'>.</span></span>
            </div>
        </div>
    );
}

export default FooterCpt