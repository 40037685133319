import React, { useState, useEffect, useCallback } from "react";
import _ from 'lodash';
import './menu.style.scss'
import { useTranslation } from "react-i18next";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function MenuCpt(props: any) {
    const [t] = useTranslation();

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [menuItems, setMenuItems] = 
        useState([
            {
                title: "title.home",
                checked: true,
                passed: true
            },
            {
                title: "title.about-me",
                checked: true,
                passed: false
            },
            {
                title: "title.project",
                checked: false,
                passed: false
            },
            {
                title: "title.career",
                checked: false,
                passed: false
            },
            {
                title: "title.contact",
                checked: false,
                passed: false
            }
        ]);

    const checkItems = useCallback((currentPage: any) => {
        // Copy all items
        let menuItemsCopy = _.cloneDeep(menuItems);

        // Map and checked the good items
        _.map(menuItemsCopy, (item, index) => {
            item.checked = false;

            if((currentPage+1) >= index)
                item.checked = true;
        })

        // Set menu items
        setMenuItems(menuItemsCopy);
    }, [menuItems]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        // Set checked item
        checkItems(props.currentPage);
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [props.currentPage]);

    return (
        <div className={`menu ${props.init ? 'fade-in' : 'fade-out'}`}>
            {
                _.map(menuItems, (item, index) => {
                    let lineHeightChecked, lineWidthChecked;

                    // 9rem base height for screen height 931px
                    let lineHeightBase = 9;
                    // Calculate height from screen height base and current screen height
                    let lineHeight = (lineHeightBase * windowDimensions.height) / 931;

                    // Width
                    let lineWidth = 8;

                    // max-width 1000px
                    // Small screen
                    if(windowDimensions.width < 1000) {
                        lineHeightChecked = lineHeight = 0.1;
                        lineWidthChecked = item.checked ? lineWidth : 0;
                    }
                    // Big screen
                    else {
                        lineHeightChecked = item.checked ? lineHeight : 0;
                        lineWidthChecked = lineWidth = 0.1;
                    }

                    return (
                        <div key={index} className="item">
                            {/* Separate line */}
                            { index !== 0 &&
                                <>
                                    <div className={`separate-line`} style={{height: lineHeight + "rem", width: lineWidth + "rem"}}></div>
                                    <div className={`separate-line bg-color-primary`} 
                                         style={{position: 'absolute', height: lineHeightChecked + "rem", width: lineWidthChecked + "rem"}}></div>
                                </>
                            }

                            <div className="group-item pointer" onClick={() => props.setPaging(index-1)}>
                                {/* Circle */}
                                <div className={`circle ${item.checked ? 'bg-color-primary' : ''}`}></div>
                                {/* Title */}
                                <span className={`${item.checked ? 'color-primary bold' : ''}`} >{t(item.title)}</span>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default MenuCpt