import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from '@emailjs/browser';

import './contact.style.scss';
import { InputCpt, ButtonCpt } from "views/components";
import { useEffect } from 'react';


function ContactPage(props: any) {
    const [emailError, setEmailError] = useState(false);
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [captcha, setCaptcha] = useState(null);
    const [sendOK, setSendOK] = useState(false);
    const captcha_key = process.env.REACT_APP_CAPTCHA_KEY;

    useEffect(() => {
        let condition = email && email !== "" && !message && message !== "" && captcha && !emailError;
        setSendOK(condition);
    }, [email, message, captcha])

    const onSubmit = async () => {
        if (!email || email === "" || !message || message === "" || emailError)
            return;

        var templateParams = {
            email: email,
            message: message
        };

        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, templateParams)
            .then(function (response) {
                console.log('SUCCESS!', response.status, response.text);
            }, function (err) {
                console.log('FAILED...', err);
            });
    };

    return (
        <div className="contact-page content">
            <InputCpt type="email" value={email} updateValue={setEmail} setError={setEmailError} placeholder="EMAIL" required />
            <InputCpt type="textarea" value={message} updateValue={setMessage} placeholder="MESSAGE" style={{ maxHeight: '25rem', height: "100%" }} required />

            <ReCAPTCHA
                sitekey={captcha_key}
                onChange={setCaptcha}
            />

            <ButtonCpt text="ENVOYER" click={onSubmit} disabled={!sendOK} style={{ marginTop: '2.6rem' }} />
        </div>
    );
}

export default ContactPage;
